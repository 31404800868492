const colors = {
  PRIMARY_COLOR: '#8432DF',
  MAIN_MAGENTA: '#FF12DA',
  MID_MAGENTA: '#C020D0',
  WHITE_COLOR: '#FFFFFF',
  HEADING_COLOR: '#131D37',
  LIGHT_GRAY: '#AAAAAA',
  LIGHTER_GRAY: '#F9F9F9',
  LIGHT_GRAY_SECOND: '#DEDEDE',
  LIGHT_GRAY_THIRD: '#EAEAEA',
  TEXT_BLACK: '#414141',
  BLACK_COLOR: '#000000',
  NOTIFICATION_GREEN: '#0BA866',
  NOTIFICATION_RED: '#FF0000',
  STAR_YELLOW: '#FFC107',
};

export default colors;
