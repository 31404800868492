import { useState, useEffect, Children, cloneElement, createRef } from 'react';
import { mobileRegex1, mobileRegex2 } from '../../utils/mobileRegex';
import styles from './carousel.module.css';

export const CarouselItem = ({ children, width, style, className }) => {
  return (
    <section className={`${styles.carouselItem} ${className ? className : ''}`} style={Object.assign({ width: width }, style)}>
      {children}
    </section>
  );
};

const Carousel = (props) => {
  const { renderPagination, children, style, intervalTiming = 3000 } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = Children.count(children) - 1;
    } else if (newIndex >= Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const ref = createRef(null);

  useEffect(() => {
    if (mobileRegex1.test(navigator.userAgent) || mobileRegex2.test(navigator.userAgent.substring(0, 4))) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        updateIndex(activeIndex + 1);
      }
    }, intervalTiming);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  var xDown = null;
  var yDown = null;

  function getTouches(e) {
    return e.touches;
  }

  function handleTouchStart(e) {
    const firstTouch = getTouches(e)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleClickStart(e) {
    xDown = e.clientX;
    yDown = e.clientY;
  }

  function handleTouchMove(e) {
    if (!xDown || !yDown) return;

    var xUp = null, yUp = null;

    if (isMobile || 'touchstart' in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints) {
      console.log(e)
      xUp = e?.changedTouches[0].clientX;
      yUp = e?.changedTouches[0].clientY;
    } else {
      xUp = e?.clientX;
      yUp = e?.clientY;
    }

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    console.log(xDiff);

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff < -100) {
        // left
        // console.log('left')
        updateIndex(activeIndex - 1);
      } else {
        // console.log('right')
        updateIndex(activeIndex + 1);
      }
    }

    xDown = null;
    yDown = null;
  }

  useEffect(() => {
    if (ref?.current) {
      if (isMobile || 'touchstart' in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints) {
        ref.current.addEventListener('touchstart', handleTouchStart, false);
        ref.current.addEventListener('touchend', handleTouchMove, { once: true });
      } else {
        ref.current.addEventListener('mousedown', handleClickStart, false);
        ref.current.addEventListener('mouseup', handleTouchMove, { once: true });
      }
    }
    // eslint-disable-next-line
  }, [ref]);

  return (
    <section
      onMouseDown={() => setIsPaused(true)}
      onMouseUp={() => setIsPaused(false)}
      className={styles.carousel}
      style={style}
      ref={ref}
    >
      <section className={styles.inner} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {Children.map(children, (child) => cloneElement(child, { width: '100%' }))}
      </section>
      {renderPagination && (
        <section className={styles.pagination}>
          {Children.map(children, (_, index) => (
            <button
              className={`${styles.pagination} ${index === activeIndex ? styles.activePagination : ''}`}
              onClick={() => updateIndex(index)}
            >
              {index + 1}
            </button>
          ))}
        </section>
      )}
    </section>
  );
};

export default Carousel;
