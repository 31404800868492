// eslint-disable-next-line
export default {
  translation: {
    ERRORS: {
      error: '¡Error!',
      warning: '¡Advertencia!',
      success: '¡Realizado!',
      yes: 'Sí',
      no: 'No',
      continue: 'Continuar',
      serverError: 'Algo ha salido mal, por favor intenelo de nuevo mas tarde',
      notFound: 'No se ha encontrado el post, es posible el dueño lo haya eliminado o lo haya archivado',
      timeOut: 'El servidor se ha tardado demasiado en responder, compruebe su conexion a internet e intentelo mas tarde',
      sessionExpired: 'Su sesion ha expirado, por favor ingrese nuevamente',
      chatsNotFound: 'No se encontraron chats.',
      postsNotFound: 'No se encontraron productos.',
      noValidToken: 'Token no valido',
    },
    GENERAL_MSGS: {
      pleaseWait: 'Por favor espere...',
    },
    HEADER: {
      title: 'Compra | Vende | Cambia',
      altTitle: 'Fortodos \nCompra | Vende | Cambia',
    },
    FOOTER: {
      terms: 'Terminos y condiciones',
      privacy: 'Politica de privacidad',
    },
    HOME: {
      promotionText: {
        0: 'La App para',
        1: 'vender y comprar',
        2: 'cosas usadas!',
      },
      download: '¡Descárgala ya!',
      confirmAccount: 'Estamos confirmando su usuario',
      or: 'O'
    },
    TERMS: {
      title: 'Términos y Condiciones',
      0: {
        title: 'Resumen de términos y condiciones',
        content: {
          0: 'ForTodos es una compañía de tecnología que ofrece servicios vinculados principalmente al comercio electrónico.',
          1: 'ForTodos es una plataforma de comercio electrónico donde los usuarios pueden vender, comprar, donar, intercambiar productos usados. \nPara poder operar en la plataforma todos los Usuarios deberán aceptar los Términos y Condiciones, los anexos y la Declaración de Privacidad.',
          2: 'Cada Usuario es responsable de los datos personales que brinda al momento de registrarse y se obliga a mantenerlos actualizados. Además, es el único responsable del uso y resguardo de su contraseña.',
          3: 'En algunos casos, podremos cobrar una tarifa por el uso de los servicios que integran el ecosistema de ForTodos, que el Usuario se compromete a pagar.',
        },
      },
      1: {
        title: 'ForTodos',
        content: {
          0: 'ForTodos es una compañía de tecnología que ofrece servicios vinculados principalmente al comercio electrónico.',
          1: 'Los servicios que ofrece ForTodos en los sitios www.fortodos.com, www.fortodos.app y sus aplicaciones móviles (iOS y Android)  están diseñados para formar un ecosistema que permita a las personas vender, comprar, donar, intercambiar y realizar otras actividades comerciales con tecnología aplicada (de ahora en adelante: “Ecosistema ZAO”).',
        },
      },
      2: {
        title: 'Términos y Condiciones',
        content: {
          0: 'Estos términos y condiciones y los anexos que explican los servicios del Ecosistema ZAO (de ahora en adelante: “Términos y Condiciones”) regulan la relación entre ForTodos y las  personas que usan sus servicios (“Usuarios”).',
          1: 'Los Usuarios aceptan estos Términos y Condiciones desde el momento en que se registran en el Sitio y usan el Ecosistema ZAO.',
          2: 'Cuando debamos hacer cambios importantes en nuestros servicios, publicaremos las modificaciones con 10 días corridos de anticipación para que los Usuarios puedan revisarlas y seguir usando el Ecosistema ZAO. En ningún caso afectarán las operaciones que ya hayan finalizado.',
          3: 'Los Usuarios que no tengan obligaciones pendientes con ForTodos o con otros Usuarios, podrán finalizar la relación con ForTodos cancelando su cuenta.',
        },
      },
      3: {
        title: 'Capacidad',
        content: {
          0: 'Podrán usar nuestros servicios las personas mayores de edad que tengan capacidad legal para contratar.',
          1: 'Quien use el Ecosistema ZAO en representación de una empresa deberá tener capacidad para contratar a nombre de ella. Además, para poder usar la cuenta, el Usuario debe encontrarse activo.',
        },
      },
      4: {
        title: 'Registro y Cuenta',
        content: {
          0: 'Quien quiera usar los servicios de ForTodos, deberá completar el formulario de registro con los datos que le sean requeridos. Al completarlo, se compromete a hacerlo de manera exacta, precisa y verdadera y a mantener sus datos siempre actualizados. El Usuario será el único responsable de la certeza de sus datos de registro. Sin perjuicio de la información brindada en el formulario, podremos solicitar y/o consultar información adicional para corroborar la identidad del Usuario.',
          1: 'La cuenta es personal, única e intransferible, es decir que bajo ningún concepto se podrá vender o ceder a otra persona. Se accede a ella con la clave personal de seguridad que haya elegido y que deberá mantener bajo estricta confidencialidad.',
          2: 'Podremos rechazar una solicitud de registro o bien cancelar un registro ya aceptado, sin que esto genere derecho a un resarcimiento. No podrán registrarse nuevamente en el Sitio los Usuarios que hayan sido inhabilitados previamente. Tampoco podrán registrarse quienes estén incluidos o relacionados a personas incluidas en listas nacionales o internacionales de sanciones.',
          3: 'Además, en caso de detectar el uso de más de una cuenta, podremos aplicar retenciones, débitos y/o cualquier otra medida si consideramos que ese accionar puede perjudicar al resto de las personas que usan el Sitio o a ForTodos, más allá de las sanciones que pudieran corresponder.',
        },
      },
      5: {
        title: 'Privacidad de datos',
        content: {
          0: 'En ForTodos hacemos un uso responsable de la información personal, protegiendo la privacidad de los Usuarios que nos confiaron sus datos y tomando las medidas necesarias para garantizar la seguridad en nuestro Ecosistema ZAO.',
          link: 'Conocé más sobre nuestra Declaración de Privacidad.',
        },
      },
      6: {
        title: 'Sanciones',
        content: {
          0: 'En caso que el Usuario incumpliera una ley o los Términos y Condiciones, podremos advertir, suspender, restringir o inhabilitar temporal o definitivamente su cuenta, sin perjuicio de otras sanciones que se establezcan en las reglas de uso particulares de los servicios de ForTodos.',
        },
      },
      7: {
        title: 'Responsabilidad',
        content: {
          0: 'ForTodos será responsable por cualquier defecto en la prestación de su servicio, en la medida en que le sea imputable y con el alcance previsto en las leyes vigentes.',
        },
      },
      8: {
        title: 'Tarifas',
        content: {
          0: 'ForTodos podrá cobrar por sus servicios y el Usuario se compromete a pagarlos a tiempo.',
          1: 'Podremos modificar o eliminar las tarifas en cualquier momento con el debido preaviso establecido en la cláusula 2 de estos Términos y Condiciones. De la misma manera, podremos modificar las tarifas temporalmente por promociones en favor de los Usuarios.',
          2: 'El Usuario autoriza a ForTodos a retener y/o debitar los fondos existentes y/o futuros de las cuentas bancarias o tarjetas que haya registrado en ella, para saldar las tarifas impagas o cualquier otra deuda que pudiera tener.',
          3: 'Para conocer el detalle de las tarifas de cada servicio, los Usuarios deberán consultar los términos y condiciones correspondientes.',
          4: 'En todos los casos se emitirá la factura de conformidad con los datos fiscales que las personas tengan cargados en su cuenta.',
        },
      },
      9: {
        title: 'Propiedad Intelectual',
        content: {
          0: 'ForTodos y/o sus sociedades relacionadas son propietarias de todos los derechos de propiedad intelectual sobre sus sitios, todo su contenido, servicios, productos, marcas, nombres comerciales, logos, diseños, imágenes, frases publicitarias, derechos de autor, dominios, programas de computación, códigos, desarrollos, software, bases de datos, información, tecnología, patentes y modelos de utilidad, diseños y modelos industriales, secretos comerciales, entre otros (“Propiedad Intelectual”) y se encuentran protegidos por leyes nacionales e internacionales.',
          1: 'Aunque ForTodos otorga permiso para usar sus productos y servicios conforme a lo previsto en los Términos y Condiciones, esto no implica una autorización para usar su  Propiedad Intelectual, excepto consentimiento previo y expreso de ForTodos y/o sus sociedades vinculadas. En cualquier caso, los usuarios vendedores que usen dichos productos y servicios no podrán utilizar la Propiedad Intelectual de ForTodos de una manera que cause confusión en el público y deberán llevar a cabo su actividad comercial bajo una marca o nombre comercial propio y distintivo, que no resulte confundible con la marca ForTodos y su familia de marcas.',
          2: 'Está prohibido usar nuestros productos o servicios para fines ilegales, realizar cualquier tipo de ingeniería inversa u obras derivadas, utilizar herramientas de búsqueda o de extracción de datos y contenidos de nuestra plataforma para su reutilización y/o crear bases de datos propias que incluyan en todo o en parte nuestro contenido sin nuestra expresa autorización. Está también prohibido el uso indebido, sin autorización y/o contrario a la normativa vigente y/o que genere confusión o implique uso denigratorio y/o que le cause perjuicio, daños o pérdidas a ForTodos y/o a sus sociedades relacionadas. La utilización de los productos y servicios de ForTodos tampoco implica la autorización para usar propiedad intelectual de terceros que pueda estar involucrada, cuyo uso quedará bajo exclusiva responsabilidad del usuario.',
          3: 'En caso que un Usuario o cualquier publicación infrinja la Propiedad Intelectual de ForTodos o de terceros, ForTodos podrá remover dicha publicación total o parcialmente), sancionar al usuario conforme a lo previsto en estos Términos y Condiciones y ejercer las acciones extrajudiciales y/o judiciales correspondientes.',
        },
      },
      10: {
        title: 'Indemnidad',
        content: {
          0: 'El Usuario mantendrá indemne a ForTodos y sus sociedades relacionadas, así como a quienes la dirigen, suceden, administran, representan y/o trabajan en ellas, por cualquier reclamo administrativo o judicial iniciado por otros usuarios, terceros o por cualquier Organismo, relacionado con sus actividades en el Ecosistema ZAO. \nEn virtud de esa responsabilidad, podrán realizar compensaciones, retenciones u otras medidas necesarias para la reparación de pérdidas, daños y perjuicios, cualquiera sea su naturaleza.',
        },
      },
      11: {
        title: 'Anexos',
        content: {
          0: 'Además de estos Términos y Condiciones, cada servicio del Ecosistema ZAO tiene sus propias reglas de uso:',
          list: {
            0: 'ForTodos',
          },
        },
      },
      12: {
        title: 'Jurisdicción y Ley Aplicable',
        content: {
          0: 'Estos Términos y Condiciones se rigen por la ley local. Toda controversia derivada de su aplicación, interpretación, ejecución o validez será resuelta por los tribunales nacionales ordinarios competentes, con asiento en la capital, salvo disposición específica de normas de orden público, como por ejemplo, legislación relativa al Consumidor. Para todos los efectos relacionados con estos Términos y Condiciones y con el uso del sitio',
        },
      },
    },
    PRIVACY: {
      title: 'Politicas de privacidad',
      0: {
        title: 'Quiénes somos',
        content: {
          0: (domain) => `La dirección de nuestro sitio web es: ${domain}`,
        },
      },
      1: {
        title: 'Comentarios',
        content: {
          0: 'Cuando los visitantes dejan comentarios en el sitio, recopilamos los datos que se muestran en el formulario de comentarios, y también la dirección IP del visitante y la cadena del agente de usuario del navegador para ayudar a la detección de spam',
          1: 'Se puede proporcionar una cadena anónima creada a partir de su dirección de correo electrónico (también llamada hash) al servicio Gravatar para ver si la está utilizando. La política de privacidad del servicio Gravatar está disponible aquí: https://automattic.com/privacy/. Después de la aprobación de su comentario, su foto de perfil es visible para el público en el contexto de su comentario.',
        },
      },
      2: {
        title: 'Medios',
        content: {
          0: 'Si subes imágenes al sitio web, debes evitar subir imágenes con datos de ubicación incrustados (GPS EXIF) incluidos. Los visitantes del sitio web pueden descargar y extraer cualquier dato de ubicación de las imágenes del sitio web.',
        },
      },
      3: {
        title: 'Cookies',
        content: {
          0: 'Si deja un comentario en nuestro sitio, puede optar por guardar su nombre, dirección de correo electrónico y sitio web en cookies. Estos son para su comodidad, para que no tenga que volver a rellenar sus datos cuando deje otro comentario. Estas cookies durarán un año.',
          1: 'Si visita nuestra página de inicio de sesión, estableceremos una cookie temporal para determinar si su navegador acepta cookies. Esta cookie no contiene datos personales y se descarta cuando cierra su navegador.',
          2: 'Cuando inicie sesión, también configuraremos varias cookies para guardar su información de inicio de sesión y sus opciones de visualización de pantalla. Las cookies de inicio de sesión duran dos días y las cookies de opciones de pantalla duran un año. Si selecciona "Recordarme", su inicio de sesión persistirá durante dos semanas. Si cierra sesión en su cuenta, se eliminarán las cookies de inicio de sesión.',
          3: 'Si editas o publicas un artículo, se guardará una cookie adicional en tu navegador. Esta cookie no incluye datos personales y simplemente indica el ID de publicación del artículo que acaba de editar. Caduca después de 1 día.',
        },
      },
      4: {
        title: 'Contenido incrustado de otros sitios web',
        content: {
          0: 'Los artículos en este sitio pueden incluir contenido incrustado (por ejemplo, videos, imágenes, artículos, etc.). El contenido incrustado de otros sitios web se comporta exactamente de la misma manera que si el visitante hubiera visitado el otro sitio web.',
          1: 'Estos sitios web pueden recopilar datos sobre usted, usar cookies, incrustar seguimiento adicional de terceros y monitorear su interacción con ese contenido incrustado, incluido el seguimiento de su interacción con el contenido incrustado si tiene una cuenta y ha iniciado sesión en ese sitio web .',
        },
      },
      5: {
        title: 'Con quién compartimos sus datos',
        content: {
          0: 'Si solicita un restablecimiento de contraseña, su dirección IP se incluirá en el correo electrónico de restablecimiento.',
        },
      },
      6: {
        title: 'Durante cuánto tiempo conservamos sus datos',
        content: {
          0: 'Si deja un comentario, el comentario y sus metadatos se conservan indefinidamente. Esto es para que podamos reconocer y aprobar cualquier comentario de seguimiento automáticamente en lugar de mantenerlos en una cola de moderación.',
          1: 'Para los usuarios que se registran en nuestro sitio web (si los hay), también almacenamos la información personal que proporcionan en su perfil de usuario. Todos los usuarios pueden ver, editar o eliminar su información personal en cualquier momento (excepto que no pueden cambiar su nombre de usuario). Los administradores del sitio web también pueden ver y editar esa información.',
        },
      },
      7: {
        title: 'Qué derechos tienes sobre tus datos',
        content: {
          0: 'Si tiene una cuenta en este sitio o ha dejado comentarios, puede solicitar recibir un archivo exportado de los datos personales que tenemos sobre usted, incluidos los datos que nos haya proporcionado. También puede solicitar que eliminemos cualquier dato personal que tengamos sobre usted. Esto no incluye ningún dato que estemos obligados a conservar con fines administrativos, legales o de seguridad.',
        },
      },
      8: {
        title: 'Dónde se envían sus datos',
        content: {
          0: 'Los comentarios de los visitantes pueden verificarse a través de un servicio de detección automática de spam.',
        },
      },
    },
  },
};
