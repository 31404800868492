import i18n from '../../../i18n';
import styles from './homeHeader.module.css';
import LogoEs from '../../../assets/home/home_header/logo_es.svg';
import LogoEn from '../../../assets/home/home_header/logo_en.svg';
import config from '../../../config';
import { SafeLink, SafeUrl } from '../../../utils/safeUrl';
import FlagSelect from '../../../utils/flag-select/flagSelect';
import { useLocation } from 'react-router-dom';
import routes from '../../../routes/types';

const HomeHeader = () => {
  const { language, t } = i18n;

  const { pathname: path } = useLocation();

  const ImgEl = language === 'es' ? LogoEs : LogoEn;

  console.log(path);

  const isHome = [routes.HOME_ROUTE, routes.ROOT_ROUTE].includes(path);

  const SafeElem = isHome ? SafeUrl : SafeLink;

  return (
    <header className={styles.header}>
      <section className={styles.headerContent}>
        <SafeElem {...(isHome ? { url: config.MAIN_DOMAIN } : { link: routes.HOME_ROUTE })}>
          <img src={ImgEl} className={styles.logo} title={t('HEADER.altTitle')} alt={config.MAIN_DOMAIN} />
        </SafeElem>
        <FlagSelect langs={['es', 'en']} style={{ marginTop: -10 }} />
      </section>
    </header>
  );
};

export default HomeHeader;
