import SplashLogo from '../../assets/splash/splash_logo.svg';
import SplashLogoEs from '../../assets/splash/splash_logo_es.svg'
import Bag from '../../assets/splash/bag.webp';
import Camera from '../../assets/splash/camera.webp';
import Chair from '../../assets/splash/chair.webp';
import Guitar from '../../assets/splash/guitar.webp';
import Headphones from '../../assets/splash/headphones.webp';
import Joystick from '../../assets/splash/joystick.webp';
import Watch from '../../assets/splash/watch.webp';
import styles from './splashScreen.module.css';
import i18n from '../../i18n';

const SplashScreen = () => {

  const { language } = i18n

  console.log(language)
  
  const imagesArr = [
    { className: styles.camera, src: Camera, alt: 'camera' },
    { className: styles.chair, src: Chair, alt: 'chair' },
    { className: styles.headphones, src: Headphones, alt: 'headphones' },
    { className: styles.bag, src: Bag, alt: 'bag' },
    { className: styles.guitar, src: Guitar, alt: 'guitar' },
    { className: styles.joystick, src: Joystick, alt: 'joystick' },
    { className: styles.watch, src: Watch, alt: 'watch'},
  ];

  return (
    <section className={styles.container}>
      {imagesArr.map((item, i) => (
        <img src={item.src} alt={`${item.alt}.webp`} className={item.className} draggable='false' key={i} />
      ))}
      <img src={language === 'es' ? SplashLogoEs : SplashLogo} alt='splash_logo.svg' className={styles.logo} />
    </section>
  );
};

export default SplashScreen;
