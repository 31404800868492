import { useDispatch } from "react-redux";
import { showAlert } from "../redux/alertSlice";

export const useAlert = () => {
  const dispatch = useDispatch()
  return (isOpen, type, title, content, yes, no, loading) => {
    dispatch(
      showAlert({isOpen, type, title, content, yes, no, loading})
    )
  }
}