import Modal from '../../../utils/modal/modal';
import { useState, useEffect } from 'react';
import styles from './zoomModal.module.css';

const ZoomModal = (props) => {
  const { isOpen, onRequestClose, src } = props;

  const [overlayPrg, setOverlayPrg] = useState(0);
  const [modalPrg, setModalPrg] = useState(0);

  const openAnimated = () => {
    if (!isOpen) return;
    setOverlayPrg(1);
    setTimeout(() => {
      setModalPrg(1);
    }, 300);
  };

  const closeAnim = () => {
    setModalPrg(0);
    setTimeout(() => {
      setOverlayPrg(0);
    }, 300);
    setTimeout(() => onRequestClose(), 600);
  };

  useEffect(() => {
    openAnimated();
    return () => {
      setOverlayPrg(0);
      setModalPrg(0);
    }
  }, [isOpen]);

  console.log({
    modalPrg, overlayPrg
  })

  return (
    <Modal
      overlayClassName={styles.overlay}
      modalClassName={styles.modal}
      isOpen={isOpen}
      outsideClick={closeAnim}
      modalStyle={{ marginBottom: modalPrg > 0 ? 0 : -10000 }}
      overlayStyle={{ opacity: overlayPrg > 0 ? 1 : 0 }}
    >
      <img src={src} />
    </Modal>
  );
};

export default ZoomModal;
