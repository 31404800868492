import Header from '../../components/home/home_header/homeHeader';
import styles from './home.module.css';
import Ball1 from '../../assets/home/ball1.webp';
import Ball2 from '../../assets/home/ball2.webp';
import Ball3 from '../../assets/home/ball3.webp';
import Ball5 from '../../assets/home/ball5.webp';
import Ball6 from '../../assets/home/ball6.webp';
import AppStoreEn from '../../assets/home/app_store_en.svg';
import AppStoreEs from '../../assets/home/app_store_es.svg';
import GooglePlayEn from '../../assets/home/google_play_en.svg';
import GooglePlayEs from '../../assets/home/google_play_es.svg';
// import Line1 from '../../assets/home/line1.svg';
// import Line2 from '../../assets/home/line2.svg';
// import WebAppEn from '../../assets/home/web_app_next_en.svg';
// import WebAppEs from '../../assets/home/web_app_next_es.svg';
import QRCode from '../../assets/home/qr_code.svg';
import i18n from '../../i18n';
import { SafeUrl } from '../../utils/safeUrl';
import config from '../../config';
import Phone1 from '../../assets/home/phone1.webp';
import Phone2 from '../../assets/home/phone2.webp';
import Phone3 from '../../assets/home/phone3.webp';
import Carousel, { CarouselItem } from '../../components/carousel/carousel';
import Footer from '../../components/home/home_footer/homeFooter';
import { useEffect, useState } from 'react';
import { useAlert } from '../../hooks/useAlert';
import { confirmEmail } from './homeActions';
import ZoomModal from '../../components/home/zoom_modal/zoomModal';
import { getOS } from '../../utils/getOs';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { t, language } = i18n;

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const btnsMap = [
    {
      source: language === 'es' ? AppStoreEs : AppStoreEn,
      url: config.APP_STORE_DOMAIN,
    },
    {
      source: language === 'es' ? GooglePlayEs : GooglePlayEn,
      url: config.GOOGLE_PLAY_DOMAIN,
    },
    // {
    //   source: language === 'es' ? WebAppEs : WebAppEn,
    //   url: config.MAIN_DOMAIN,
    // },
  ];

  const showAlert = useAlert();

  const images = [Phone1, Phone2, Phone3];

  const confirmEmailFunct = async (token) => {
    try {
      const response = await confirmEmail({ token });
      showAlert(true, 'success', undefined, response?.body, null, null, false);
    } catch (e) {
      const error = e?.data?.error?.details?.message ?? e?.data?.error?.message ?? t('ERRORS.serverErrors');
      showAlert(true, 'error', undefined, error, null, null, false);
    }
  };

  const redirect = (os) => {
    let url = '';

    if (['iOS', 'Mac OS'].includes(os)) {
      url = config.APP_STORE_DOMAIN;
    } else if (os === 'Windows') {
      url = config.MICROSOFT_STORE_DOMAIN;
    } else if (['Android', 'Linux'].includes(os)) {
      url = config.GOOGLE_PLAY_DOMAIN;
    }

    window.location.replace(url);
  };

  useEffect(() => {
    (async () => {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');
      const ref = url.searchParams.get('ref');
      if (token) {
        showAlert(true, 'warning', t('GENERAL_MSGS.pleaseWait'), t('HOME.confirmAccount'), null, null, true);
        await confirmEmailFunct(token);
      }
      if (ref) {
        const os = getOS();
        redirect(os);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <main className={styles.mainContainer}>
      <Header />
      <img src={Ball1} className={`${styles.homeBalls} ${styles.ball1}`} alt='' draggable='false' />
      <img src={Ball2} className={`${styles.homeBalls} ${styles.ball2}`} alt='' draggable='false' />
      <img src={Ball3} className={`${styles.homeBalls} ${styles.ball3}`} alt='' draggable='false' />
      <img src={Ball5} className={`${styles.homeBalls} ${styles.ball5}`} alt='' draggable='false' />
      <img src={Ball6} className={`${styles.homeBalls} ${styles.ball6}`} alt='' draggable='false' />
      <section className={styles.content}>
        <section className={styles.leftSection}>
          <section className={styles.homeTitle}>
            <section className={styles.homeTextbox}>
              <h2>{t('HOME.promotionText.0')}</h2>
            </section>
            <section className={styles.homeTextbox}>
              <h2>{t('HOME.promotionText.1')}</h2>
              <section className={styles.lineContainer}>
                <svg width={'100%'} viewBox='0 0 489 22' fill='none' xmlns='http://www.w3.org/2000/svg' overflow={'visible'}>
                  <path
                    d='M1 9C33.8333 6.88716 119.2 2.73192 198 3.01363C276.8 3.29534 424.167 7.12192 488 9'
                    className={styles.path}
                  />
                  <path
                    d='M24 19C53.1253 17.2393 128.851 13.7766 198.752 14.0114C268.652 14.2461 399.376 17.4349 456 19'
                    className={styles.path}
                  />
                </svg>
              </section>
            </section>
            <section className={styles.homeTextbox}>
              <h2>{t('HOME.promotionText.2')}</h2>
            </section>
          </section>
          <p className={styles.downloadText}>{t('HOME.download')}</p>
          <section className={styles.homeLinks}>
            {btnsMap.map((item, i) => (
              <SafeUrl url={item.url} key={i} className={styles.linkBtn}>
                <img src={item.source} alt='' />
              </SafeUrl>
            ))}
          </section>
          <section className={styles.qrArea}>
            <p>{t('HOME.or')}</p>
            <section className={styles.qrCode} onClick={() => setIsOpen(true)}>
              <img src={QRCode} alt='' />
            </section>
          </section>
        </section>
        <section className={styles.rightSection}>
          <Carousel intervalTiming={5000}>
            {images.map((item, i) => (
              <CarouselItem key={i}>
                <img src={item} className={styles.carouselImage} draggable='false' alt={`phone${i}.webp`} />
              </CarouselItem>
            ))}
          </Carousel>
        </section>
      </section>
      <Footer />
      <ZoomModal src={QRCode} isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
    </main>
  );
};

export default Home;
