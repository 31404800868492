import { postData } from '../../fetch';

export const confirmEmail = (token) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await postData('/user/confirm-email', token);
      resolve(response);
    } catch (e) {
      console.log(e);
      reject(e);
    }
  });
