import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  type: null,
  title: undefined,
  content: undefined,
  yes: null,
  no: null,
  loading: false,
  btnText: undefined
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert(state, action) {
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type;
      state.title = action.payload.title;
      state.content = action.payload.content;
      state.yes = action.payload.yes;
      state.no = action.payload.no;
      state.loading = action.payload.loading;
      state.btnText = action.payload.btnText;
    },
    closeAlert(state) {
      state.isOpen = false;
      state.type = null;
      state.title = undefined;
      state.content = undefined;
      state.yes = null;
      state.no = null;
      state.loading = false;
      state.btnText = undefined;
    },
  },
});

export const { showAlert, closeAlert } = alertSlice.actions;

export default alertSlice.reducer;