import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import alertSlice from './alertSlice';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: null,
};

const reducers = combineReducers({
  user: userSlice,
  alert: alertSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['filterModal', 'register'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          `payload.$$typeof`,
          `payload.type.$$typeof`,
          `payload.type.render`,
          `payload.type`,
          `payload.no.onPress`,
          `register`,
          `rehydrate`,
        ],
        // // Ignore these paths in the state
        ignoredPaths: ['filterModal.children', 'alert.no.onPress', 'alert.yes.onPress'],
      },
    }),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
