import routes from "../routes/types";

export const SafeUrl = (props) => {
  const { url, children, text, newTab = true, ...rest } = props;

  const isSafe = (dangerousURL) => {
    const testUrl = new URL(dangerousURL);
    const protocols = ['http:', 'https:', 'tel:', 'mailto:'];

    if (protocols.includes(testUrl.protocol)) return true;
    return false;
  };

  const dangerousURL = url;
  const safeURL = isSafe(dangerousURL) ? dangerousURL : null;

  return (
    <a href={safeURL} {...(newTab && { target: '_blank', rel: 'noopener noreferer' })} {...rest}>
      {text || children}
    </a>
  );
};

export const SafeLink = (props) => {

  const { link, state, children, newTab, text, ...rest } = props;
  
  const isSafe = (dangerousLINK) => {
    const exists = Object.values(routes).includes(dangerousLINK);
    if (exists) return true;
    return false;
  }

  const dangerousLINK = link;

  const safeLINK = isSafe(dangerousLINK) ? dangerousLINK : null;

  const onClick = (e) => {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    e.preventDefault();

    window.history.pushState(state || {}, '', safeLINK);

    const nativeEvent = new PopStateEvent('popstate');
    window.dispatchEvent(nativeEvent)
  }

  return (
    <a href={safeLINK} onClick={onClick} {...(newTab && { target: '_blank', rel: 'noopener noreferer' })} {...rest}>
      {text || children}
    </a>
  )
}