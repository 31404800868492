import { useState } from 'react';
import i18n from '../../i18n';
import SpainFlag from '../../assets/flag_select/spain_flag.svg';
import BritishFlag from '../../assets/flag_select/british_flag.svg';
import styles from './flagSelect.module.css';

const FlagSelect = (props) => {
  const { langs , style } = props;

  const { language } = i18n;

  const [isOpen, setIsOpen] = useState(false);
  const [selected] = useState(language === 'es' ? 'es' : 'en');

  const selectFlags = { en: BritishFlag, 'en-US': BritishFlag, es: SpainFlag, 'es-ES': SpainFlag };

  // console.log(selectFlags);

  return (
    <section className={styles.flagSelectMainContainer} style={style}>
      <section className={styles.flagContainer} onClick={() => setIsOpen(!isOpen)}>
        <img src={selectFlags[selected] ?? BritishFlag} alt={selected} className={styles.flagSelectImage} />
        <section className={styles.arrow} style={{ transform: `rotateX(${isOpen ? 180 * 3 : 0}deg)` }} />
      </section>
      {isOpen && (
        <ul className={styles.langList}>
          {langs.map((item, i) => {
            if (!selectFlags[item]) return null;
            return (
              <li
                key={i}
                onClick={() => {
                  localStorage.language = item.toLowerCase();
                  localStorage.fastRefresh = true
                  window.location.reload()
                }}
              >
                <img src={selectFlags[item]} alt='' />
                <p>{item}</p>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default FlagSelect;
