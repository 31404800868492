const config = {
  API_URL: process.env.REACT_APP_API_URL,
  // API_URL: 'http://192.168.1.104:4000',
  CRYPTO_JS_SECRET_KEY: process.env.REACT_APP_CRYPTO_JS_SECRET_KEY,
  TIME_TO_RESPONSE: process.env.REACT_APP_TIME_TO_RESPONSE,
  GOOGLE_CLOUD_API_KEY: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY,
  MAIN_DOMAIN: process.env.REACT_APP_MAIN_DOMAIN,
  APP_STORE_DOMAIN: process.env.REACT_APP_APP_STORE_DOMAIN,
  MICROSOFT_STORE_DOMAIN: process.env.REACT_APP_MICROSOFT_STORE_DOMAIN,
  GOOGLE_PLAY_DOMAIN: process.env.REACT_APP_GOOGLE_PLAY_DOMAIN,
  FBACEBOOK_URL: process.env.REACT_APP_FBACEBOOK_URL,
  INSTAGRAM_URL: process.env.REACT_APP_INSTAGRAM_URL,
  TWITTER_URL: process.env.REACT_APP_TWITTER_URL,
  TIKTOK_URL: process.env.REACT_APP_TIKTOK_URL,
};

export default config;
