import styles from './homeFooter.module.css';
import Logo from '../../../assets/splash/splash_logo.svg';
import LogoEs from '../../../assets/splash/splash_logo_es.svg';
import FacebookLogo from '../../../assets/home/home_footer/facebook_logo.svg';
import TwitterLogo from '../../../assets/home/home_footer/twitter_logo.svg';
import InstagramLogo from '../../../assets/home/home_footer/instagram_logo.svg';
import TiktokLogo from '../../../assets/home/home_footer/tiktok_logo.svg';
import i18n from '../../../i18n';
import { SafeLink, SafeUrl } from '../../../utils/safeUrl';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routes/types';

const HomeFooter = () => {
  const { language, t } = i18n;

  const navigate = useNavigate();

  const socialArr = [
    {
      url: config.FBACEBOOK_URL,
      src: FacebookLogo,
    },
    {
      url: config.TWITTER_URL,
      src: TwitterLogo,
    },
    {
      url: config.INSTAGRAM_URL,
      src: InstagramLogo,
    },
    {
      url: config.TIKTOK_URL,
      src: TiktokLogo,
    },
  ];

  return (
    <footer className={styles.footer}>
      <section className={styles.footerContent}>
        <SafeUrl url={config.MAIN_DOMAIN}>
          <img
            src={language === 'es' ? LogoEs : Logo}
            className={styles.logo}
            alt={config.MAIN_DOMAIN}
            title={t('HEADER.altTitle')}
          />
        </SafeUrl>
        <SafeUrl
          url={`${config.MAIN_DOMAIN}/blog`}
          style={{ color: '#fff', textDecoration: 'none', textTransform: 'uppercase', fontWeight: 'bold', fontSize: 18 }}
        >
          Blog
        </SafeUrl>
        <section className={styles.socialMedia}>
          {socialArr.map((item, i) => {
            return (
              <SafeUrl url={item.url} className={styles.socialMediaBtn} key={i}>
                <img src={item.src} alt={item.url} draggable='false' />
              </SafeUrl>
            );
          })}
        </section>
      </section>
      <section className={styles.footerLinkArea}>
        <SafeLink link={routes.TERMS_AND_CONDITIONS_ROUTE} className={styles.footerLink}>{t('FOOTER.terms')}</SafeLink>
        <span style={{margin: '0 10px'}}>|</span>
        <SafeLink link={routes.PRIVACY_POLICY_ROUTE} className={styles.footerLink}>{t('FOOTER.privacy')}</SafeLink>
      </section>
    </footer>
  );
};

export default HomeFooter;
