import i18n from '../i18n';
// import { decrypt } from '../utils/encrypt';
import store from '../redux/store';
import axios from 'axios';
import config from '../config';

const { t } = i18n;

const validateSesion = (e) =>
  new Promise(async (resolve, _) => {
    const state = store.getState();
    let errorToShow = '';
    if (state.user) {
      const error = e?.data?.error?.code ?? '';
      const errorMessage = e?.data?.error?.details?.message ?? e?.data?.error?.message ?? t('ERRORS.serverError');

      if (error === 'token-expired' && e.status === 429) {
        errorToShow = errorMessage;
      }

      resolve(errorToShow);
    } else {
      resolve(errorToShow);
    }
  });

/**
 * Handle fetch error method
 * @param {Object | Any} e      the error to handle
 * @returns {Promise}           the string message
 */
const handleError = async (e) => {
  let errorObj = {
    data: {
      error: {
        code: 'internal-error',
        details: '',
        message:
          e?.response?.status === 429
            ? 'Too Many Request'
            : e?.message === 'Network Error'
            ? 'No internet'
            : t('ERRORS.serverError'),
      },
    },
  };

  console.log('[handleError/ e]:', e);

  const error = e?.response?.status === 429 ? errorObj : e?.response || errorObj;

  await validateSesion(error);

  return error;
};

/**
 * POST fetch method
 * @param {String} url              Endpoint url
 * @param {Object | Any} data       Endpoint data
 * @param {Boolean} isAuth          if endpoint needs token, true by default
 * @returns {Promise}               the data from the endpoint
 */
export const postData = (url, data, isAuth = true) =>
  new Promise(async (resolve, reject) => {
    try {
      // console.log("postData / url: ", url)
      // console.log("postData / data: ", data)
      // console.log("config.API_URL: ", config.API_URL)
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language');
      const fullUrl = `${config.API_URL}${url}`;

      const response = await timeOut(
        Number(config.TIME_TO_RESPONSE),
        axios.post(fullUrl, data, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            'access-token': isAuth ? token : '',
          },
        })
      );
      resolve(response.data);
    } catch (e) {
      console.log('postData/e:', e);
      const error = await handleError(e);
      console.log('[postData/e]:', error);
      reject(error);
    }
  });

/**
 * PUT fetch method
 * @param {String}  url           Endpoint url
 * @param {Object | Any} data     Endpoint data
 * @param {Boolean} isAuth        if endpoint needs token, true by default
 * @returns {Promise}             the data from the endpoint
 */
export const putData = (url, data, isAuth = true) =>
  new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language');
      const fullUrl = `${config.API_URL}${url}`;

      const response = await timeOut(
        Number(config.TIME_TO_RESPONSE),
        axios.put(fullUrl, data, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            'access-token': isAuth ? token : '',
          },
        })
      );
      resolve(response.data);
    } catch (e) {
      const error = await handleError(e);
      reject(error);
    }
  });

/**
 * DELETE fetch method
 * @param {Object | Any} url        Endpoint url
 * @param {Boolean} isAuth          if endpoint needs token, true by default
 * @returns {Promise}               the data from the endpoint
 */
export const deleteData = (url, isAuth = true) =>
  new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language');
      const fullUrl = `${config.API_URL}${url}`;

      const response = await timeOut(
        Number(config.TIME_TO_RESPONSE),
        axios.delete(fullUrl, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            'access-token': isAuth ? token : '',
          },
        })
      );

      resolve(response.data);
    } catch (e) {
      const error = await handleError(e);
      reject(error);
    }
  });

/**
 *
 * @param {Object | Any} url        Endpoint url
 * @param {Boolean} isAuth          if endpoint needs token, true by default
 * @returns {Promise}               the data from the endpoint
 */
export const getData = (url, isAuth = true) =>
  new Promise(async (resolve, reject) => {
    try {
      const token = localStorage.getItem('token');
      const language = localStorage.getItem('language');
      const fullUrl = `${config.API_URL}${url}`;

      const response = await timeOut(
        Number(config.TIME_TO_RESPONSE),
        axios.get(fullUrl, {
          headers: {
            'content-type': 'application/json',
            'accept-language': language,
            'access-token': isAuth ? token : '',
          },
        })
      );

      resolve(response.data);
    } catch (e) {
      const error = await handleError(e);
      reject(error);
    }
  });

/**
 * Timeout for fetch request
 * @param {*} ms          milliseconds
 * @param {*} promise     the fetch promise
 */
const timeOut = (ms, promise) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({
        response: {
          data: {
            error: {
              code: 'timeout-error',
              details: '',
              message: t('ERRORS.timeOut'),
            },
          },
        },
        body: '',
      });
    }, ms);
    promise.then(resolve, reject);
  });
