import { forwardRef } from "react";
import { createPortal } from "react-dom";
import styles from './modal.module.css';

export default forwardRef((props, ref) => {
  const { isOpen, overlayClassName = '', modalClassName = '', outsideClick, overlayStyle, modalStyle, children } = props;

  const handleClick = e => {
    e.stopPropagation();
  }

  if (!isOpen) {
    return null;
  }
  return createPortal(
    <section className={styles.modal}>
      <section className={`${styles.overlay} ${overlayClassName}`} style={overlayStyle} onClick={outsideClick}>
        <section ref={ref} className={`${styles.container} ${modalClassName}`} style={modalStyle} onClick={handleClick}>
          {children}
        </section>
      </section>
    </section>,
    document.getElementById('portal')
  )
})
