import Header from '../../components/home/home_header/homeHeader';
import Footer from '../../components/home/home_footer/homeFooter';
import i18n from '../../i18n';
import { SafeLink } from '../../utils/safeUrl';
import routes from '../../routes/types';
import styles from './termsAndPrivacy.module.css';

const TermsAndPrivacy = (props) => {

  const { isPrivacy } = props;
  const { t } = i18n;

  const terms = isPrivacy ? [
    {
      title: t('PRIVACY.0.title'),
      content: [t('PRIVACY.0.content.0')(window.location.origin)]
    },
    {
      title: t('PRIVACY.1.title'),
      content: [t('PRIVACY.1.content.0'), t('PRIVACY.1.content.1')]
    },
    {
      title: t('PRIVACY.2.title'),
      content: [t('PRIVACY.2.content.0')]
    },
    {
      title: t('PRIVACY.3.title'),
      content: [t('PRIVACY.3.content.0'), t('PRIVACY.3.content.1'), t('PRIVACY.3.content.2'), t('PRIVACY.3.content.3')]
    },
    {
      title: t('PRIVACY.4.title'),
      content: [t('PRIVACY.4.content.0'), t('PRIVACY.4.content.1')]
    },
    {
      title: t('PRIVACY.5.title'),
      content: [t('PRIVACY.5.content.0')]
    },
    {
      title: t('PRIVACY.6.title'),
      content: [t('PRIVACY.6.content.0'), t('PRIVACY.6.content.1')]
    },
    {
      title: t('PRIVACY.7.title'),
      content: [t('PRIVACY.7.content.0')]
    },
    {
      title: t('PRIVACY.8.title'),
      content: [t('PRIVACY.8.content.0')]
    },
  ] : [
    {
      title: t('TERMS.0.title'),
      content: [t('TERMS.0.content.0'), t('TERMS.0.content.1'), t('TERMS.0.content.2'), t('TERMS.0.content.3')],
    },
    {
      title: t('TERMS.1.title'),
      content: [t('TERMS.1.content.0'), t('TERMS.1.content.1')],
    },
    {
      title: t('TERMS.2.title'),
      content: [t('TERMS.2.content.0'), t('TERMS.2.content.1'), t('TERMS.2.content.2'), t('TERMS.2.content.3')],
    },
    {
      title: t('TERMS.3.title'),
      content: [t('TERMS.3.content.0'), t('TERMS.3.content.1')],
    },
    {
      title: t('TERMS.4.title'),
      content: [t('TERMS.4.content.0'), t('TERMS.4.content.1'), t('TERMS.4.content.2'), t('TERMS.4.content.3')],
    },
    {
      title: t('TERMS.5.title'),
      content: [t('TERMS.5.content.0')],
      link: { path: routes.PRIVACY_POLICY_ROUTE, content: t('TERMS.5.content.link') },
    },
    {
      title: t('TERMS.6.title'),
      content: [t('TERMS.6.content.0')],
    },
    {
      title: t('TERMS.7.title'),
      content: [t('TERMS.7.content.0')],
    },
    {
      title: t('TERMS.8.title'),
      content: [
        t('TERMS.8.content.0'),
        t('TERMS.8.content.1'),
        t('TERMS.8.content.2'),
        t('TERMS.8.content.3'),
        t('TERMS.8.content.4'),
      ],
    },
    {
      title: t('TERMS.9.title'),
      content: [t('TERMS.9.content.0'), t('TERMS.9.content.1'), t('TERMS.9.content.2'), t('TERMS.9.content.3')],
    },
    {
      title: t('TERMS.10.title'),
      content: [t('TERMS.10.content.0')],
    },
    {
      title: t('TERMS.11.title'),
      content: [t('TERMS.11.content.0')],
      list: [t('TERMS.11.list.0')],
    },
    {
      title: t('TERMS.12.title'),
      content: [t('TERMS.12.content.0')],
    },
  ];

  const MapElem = (props) => {
    const { item } = props;
    return (
      <article className={styles.article}>
        <h2 className={styles.articleTitle}>{item.title}</h2>
        {item.content.map((itm, idx) => (
          <p style={{ display: item.link ? 'inline' : 'block' }} key={idx} className={styles.articleContent}>
            {itm}
            {item.link && '\u00A0'}
          </p>
        ))}
        {item.link && (
          <SafeLink link={item.link?.path} style={{ display: 'inline' }} className={styles.articleLink}>
            {item.link?.content}
          </SafeLink>
        )}
      </article>
    );
  };

  return (
    <>
      <Header />
      <main className={styles.mainContainer}>
        <h1 className={styles.title}>{isPrivacy ? t('PRIVACY.title') : t('TERMS.title')}</h1>
        {terms.map((item, i) => (
          <MapElem item={item} key={i} />
        ))}
      </main>
      <Footer />
    </>
  );
};

export default TermsAndPrivacy;
