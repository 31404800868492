import Modal from '../modal/modal';
import { useTranslation } from 'react-i18next';
import styles from './alertModal.module.css';
import WarningIcon from '../../assets/alert_modal/warning_icon.svg';
import ErrorIcon from '../../assets/alert_modal/error_icon.svg';
import SucessIcon from '../../assets/alert_modal/success_icon.svg';
import Loader from '../loader';
import { useEffect, useState } from 'react';

const AlertModal = (props) => {
  const { isOpen, onRequestClose, type, title, content, yes, no, loading, btnText } = props;

  const { t } = useTranslation();

  const [overlayPrg, setOverlayPrg] = useState(0);
  const [modalPrg, setModalPrg] = useState(0);

  const ImgType = type === 'error' ? ErrorIcon : type === 'warning' ? WarningIcon : type === 'success' ? SucessIcon : SucessIcon;
  const ImgMsg =
    type === 'error' ? 'error_icon' : type === 'warning' ? 'warning_icon' : type === 'success' ? 'success_icon' : 'success_icon';

  const openAnimated = () => {
    setOverlayPrg(1);
    setTimeout(() => {
      setModalPrg(1);
    }, 300);
  };

  const closeAnim = () => {
    setModalPrg(0);
    setTimeout(() => {
      setOverlayPrg(0);
    }, 300);
    setTimeout(() => onRequestClose(), 600);
  };

  useEffect(() => {
    openAnimated();
  }, [isOpen]);

  return (
    <Modal
      overlayClassName={styles.overlay}
      modalClassName={styles.modal}
      isOpen={isOpen}
      outsideClick={closeAnim}
      modalStyle={{ marginBottom: modalPrg > 0 ? 0 : -10000 }}
      overlayStyle={{ opacity: overlayPrg > 0 ? 1 : 0 }}
    >
      <img src={ImgType} alt={`${ImgMsg}.svg`} />
      <h2 className={styles.title}>
        {title
          ? title
          : type === 'error'
          ? t('ERRORS.error')
          : type === 'warning'
          ? t('ERRORS.warning')
          : type === 'success'
          ? t('ERRORS.success')
          : t('ERRORS.success')}
      </h2>
      {content && <p className={styles.content}>{content}</p>}
      <section className={styles.controlsContainer}>
        {loading ? (
          <Loader size={35} style={{ margin: '10px 0' }} />
        ) : (
          <>
            {yes && (
              <button className={`${styles.buttons} ${yes.purple ? styles.buttonFilled : styles.buttonWhite}`}>
                {yes.text || t('ERRORS.yes')}
              </button>
            )}
            {no && (
              <button className={`${styles.buttons} ${no.purple ? styles.buttonFilled : styles.buttonWhite}`}>
                {no.text || t('ERRORS.no')}
              </button>
            )}
            {!yes && !no && (
              <button className={`${styles.buttons} ${styles.buttonFilled}`} onClick={closeAnim}>
                {btnText || t('ERRORS.continue')}
              </button>
            )}
          </>
        )}
      </section>
    </Modal>
  );
};

export default AlertModal;
