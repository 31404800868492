// eslint-disable-next-line
export default {
  translation: {
    ERRORS: {
      error: 'Error!',
      warning: 'Warning!',
      success: 'Success!',
      yes: 'Yes',
      no: 'No',
      continue: 'Continue',
      serverError: 'Something went wrong, please try again later',
      notFound: 'The post was not found, it is possible that the owner has deleted or archived it',
      postsNotFound: 'No products found.',
      timeOut: 'The server has taken too long to respond, please check your internet connection and try again later',
      sessionExpired: 'Your session has expired, please log in again',
      chatsNotFound: 'No chats found.',
      noValidToken: 'No valid token',
    },
    GENERAL_MSGS: {
      pleaseWait: 'Please Wait...',
    },
    HEADER: {
      title: 'Buy | Sell | Trade',
      altTitle: 'Fortodos \nBuy | Sell | Trade',
    },
    FOOTER: {
      terms: 'Terms and conditions',
      privacy: 'Privacy policy',
    },
    HOME: {
      promotionText: {
        0: 'The App to',
        1: 'sell and buy',
        2: 'used things!',
      },
      download: 'Download it now!',
      confirmAccount: 'Estamos confirmando su usuario',
      or: 'Or'
    },
    TERMS: {
      title: 'Terms and Conditions',
      0: {
        title: 'Summary of terms and conditions',
        content: {
          0: 'ForTodos is a technology company that offers services related mainly to electronic commerce.',
          1: 'ForTodos is an e-commerce platform where users can sell, buy, donate, trade used products. \nIn order to operate on the platform, all Users must accept the Terms and Conditions, the annexes and the Privacy Statement.',
          2: 'Each User is responsible for the personal data provided at the time of registration and is obliged to keep them updated. In addition, he is solely responsible for the use and protection of his password. ',
          3: 'In some cases, we may charge a fee for the use of the services that make up the ForTodos ecosystem, which the User agrees to pay.',
        },
      },
      1: {
        title: '1- ForTodos',
        content: {
          0: 'ForTodos is a technology company that offers services related mainly to electronic commerce.',
          1: 'The services offered by ForTodos on the sites www.fortodos.com, www.fortodos.app and their mobile applications (iOS and Android) are designed to form an ecosystem that allows people to sell, buy, donate, exchange and carry out other commercial activities with applied technology (hereinafter: “ZAO Ecosystem”).',
        },
      },
      2: {
        title: '2- Terms and Conditions',
        content: {
          0: 'These terms and conditions and the annexes that explain the services of the ZAO Ecosystem (hereinafter: “Terms and Conditions”) regulate the relationship between ForTodos and the people who use its services (“Users”).',
          1: 'Users accept these Terms and Conditions from the moment they register on the Site and use the ZAO Ecosystem.',
          2: 'When we must make important changes to our services, we will publish the modifications 10 calendar days in advance so that Users can review them and continue using the ZAO Ecosystem. In no case will they affect operations that have already ended.',
          3: 'Users who have no outstanding obligations with ForTodos or with other Users, may terminate the relationship with ForTodos by canceling their account.',
        },
      },
      3: {
        title: '3- Capacity',
        content: {
          0: 'People of legal age who have the legal capacity to contract may use our services.',
          1: 'Whoever uses the ZAO Ecosystem on behalf of a company must have the capacity to contract on its behalf. In addition, in order to use the account, the User must be active.',
        },
      },
      4: {
        title: '4- Registration and Account',
        content: {
          0: "Whoever wants to use the services of ForTodos, must complete the registration form with the information that is required. By completing it, you agree to do so accurately, precisely and truthfully and to keep your data always up to date. The User will be solely responsible for the accuracy of their registration data. Notwithstanding the information provided in the form, we may request and/or consult additional information to corroborate the User's identity.",
          1: 'The account is personal, unique and non-transferable, that is, under no circumstances can it be sold or transferred to another person. It is accessed with the personal security code that you have chosen and that you must keep under strict confidentiality.',
          2: 'We may reject a registration request or cancel an already accepted registration, without this generating the right to compensation. Users who have been previously disabled will not be able to register again on the Site. Neither can register those who are included or related to people included in national or international lists of sanctions.',
          3: 'In addition, in case of detecting the use of more than one account, we may apply withholdings, debits and/or any other measure if we consider that this action may harm the rest of the people who use the Site or ForTodos, beyond the sanctions that may correspond.',
        },
      },
      5: {
        title: '5- Data Privacy',
        content: {
          0: 'At ForTodos we make responsible use of personal information, protecting the privacy of Users who entrusted us with their data and taking the necessary measures to guarantee security in our ZAO Ecosystem.',
          link: 'Learn more about our Privacy Statement.',
        },
      },
      6: {
        title: '6- Sanctions',
        content: {
          0: 'In the event that the User breaches a law or the Terms and Conditions, we may warn, suspend, restrict or temporarily or permanently disable his account, without prejudice to other sanctions that are established in the particular rules of use of the ForTodos services.',
        },
      },
      7: {
        title: '7- Responsibility',
        content: {
          0: 'ForTodos will be responsible for any defect in the provision of its service, to the extent that it is attributable to it and with the scope provided in current laws.',
        },
      },
      8: {
        title: '8- Rates',
        content: {
          0: 'ForTodos may charge for its services and the User agrees to pay them on time.',
          1: 'We may modify or eliminate the fees at any time with due notice set out in clause 2 of these Terms and Conditions. In the same way, we can temporarily modify the rates for promotions in favor of Users.',
          2: 'The User authorizes ForTodos to withhold and/or debit existing and/or future funds from the bank accounts or cards that they have registered in it, to settle unpaid fees or any other debt they may have.',
          3: 'To find out the details of the rates for each service, Users must consult the corresponding terms and conditions.',
          4: 'In all cases, the invoice will be issued in accordance with the tax data that people have uploaded to their account.',
        },
      },
      9: {
        title: '9- Intellectual Property',
        content: {
          0: 'ForTodos and/or its related companies own all intellectual property rights over their sites, all their content, services, products, brands, trade names, logos, designs, images, advertising phrases, copyrights, domains , computer programs, codes, developments, software, databases, information, technology, patents and utility models, industrial designs and models, trade secrets, among others (“Intellectual Property”) and are protected by national and international laws .',
          1: 'Although ForTodos grants permission to use its products and services in accordance with the provisions of the Terms and Conditions, this does not imply authorization to use its Intellectual Property, except with the prior and express consent of ForTodos and/or its related companies. In any case, the seller users who use said products and services may not use the Intellectual Property of ForTodos in a way that causes confusion in the public and must carry out their commercial activity under their own and distinctive brand or commercial name, which does not can be confused with the ForTodos brand and its family of brands.',
          2: 'It is prohibited to use our products or services for illegal purposes, carry out any type of reverse engineering or derivative works, use search tools or extract data and content from our platform for reuse and/or create your own databases that include all or part of our content without our express authorization. Improper use is also prohibited, without authorization and/or contrary to current regulations and/or that generates confusion or implies derogatory use and/or that causes prejudice, damage or loss to ForTodos and/or its related companies. The use of ForTodos products and services does not imply authorization to use the intellectual property of third parties that may be involved, the use of which will be under the sole responsibility of the user.',
          3: 'In the event that a User or any publication infringes the Intellectual Property of ForTodos or third parties, ForTodos may remove said publication in whole or in part), sanction the user in accordance with the provisions of these Terms and Conditions and exercise extrajudicial actions and/or or corresponding courts.',
        },
      },
      10: {
        title: '10- Indemnity',
        content: {
          0: 'The User will hold ForTodos and its related companies harmless, as well as those who direct, succeed, manage, represent and/or work for them, for any administrative or judicial claim initiated by other users, third parties or by any Organization, related to their activities in the ZAO Ecosystem. \nBy virtue of this responsibility, they may make compensation, withholding or other necessary measures to repair losses, damages, whatever their nature.',
        },
      },
      11: {
        title: '11- Annexes',
        content: {
          0: 'In addition to these Terms and Conditions, each ZAO Ecosystem service has its own rules of use:',
          list: {
            0: 'ForTodos',
          },
        },
      },
      12: {
        title: '12- Jurisdiction and Applicable Law',
        content: {
          0: 'These Terms and Conditions are governed by local law. Any controversy derived from its application, interpretation, execution or validity will be resolved by the ordinary national courts. competent authorities, based in the capital, unless specifically provided for in public order regulations, such as, for example, legislation relating to the Consumer. For all purposes related to these Terms and Conditions and the use of the site',
        },
      },
    },
    PRIVACY: {
      title: 'Privacy policy',
      0: {
        title: 'Who we are?',
        content: {
          0: (domain) => `Our website address is: ${domain}`,
        },
      },
      1: {
        title: 'Comments',
        content: {
          0: 'When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.',
          1: 'An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.',
        },
      },
      2: {
        title: 'Media',
        content: {
          0: 'If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.',
        },
      },
      3: {
        title: 'Cookies',
        content: {
          0: 'If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.',
          1: 'If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.',
          2: 'When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.',
          3: 'If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.',
        },
      },
      4: {
        title: 'Embedded content from other websites',
        content: {
          0: 'Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.',
          1: 'These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.',
        },
      },
      5: {
        title: 'Who we share your data with',
        content: {
          0: 'If you request a password reset, your IP address will be included in the reset email.',
        },
      },
      6: {
        title: 'How long we retain your data',
        content: {
          0: 'If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.',
          1: 'For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.',
        },
      },
      7: {
        title: 'What rights you have over your data',
        content: {
          0: 'If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.',
        },
      },
      8: {
        title: 'Where your data is sent',
        content: {
          0: 'Visitor comments may be checked through an automated spam detection service.',
        },
      },
    },
  },
};
