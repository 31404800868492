import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import i18n from '../i18n';
import Home from '../pages/home/home';
import SplashScreen from '../pages/splash/splashScreen';
import AlertModal from '../utils/alert_modal/alertModal';
import { closeAlert } from '../redux/alertSlice';
import routes from './types';
import TermsAndPrivacy from '../pages/terms-and-privacy/termsAndPrivacy';

const Nav = () => {
  const [splash, setSplash] = useState(true);

  const { t } = i18n;

  const fastRefresh = localStorage.fastRefresh;

  useEffect(() => {
    document.title = `ForTodos ${'\u2014'} ${t('HEADER.title')}`;
    if (fastRefresh) {
      localStorage.removeItem('fastRefresh');
    }
    setTimeout(() => setSplash(false), 4600);
    // eslint-disable-next-line
  }, []);

  const {
    isOpen: isAlertOpen,
    type: alertType,
    title: alertTitle,
    content: alertContent,
    yes: alertYes,
    no: alertNo,
    loading: alertLoading,
    btnText: alertText,
  } = useSelector((state) => state.alert);

  const dispatch = useDispatch();

  return (
    <Router>
      {splash && !fastRefresh ? (
        <Switch>
          <Route path='*' element={<SplashScreen />} />
        </Switch>
      ) : (
        <Switch>
          <Route path={routes.HOME_ROUTE} element={<Home />} />
          <Route path={routes.ROOT_ROUTE} element={<Home />} />
          <Route path={routes.TERMS_AND_CONDITIONS_ROUTE} element={<TermsAndPrivacy />} />
          <Route path={routes.PRIVACY_POLICY_ROUTE} element={<TermsAndPrivacy isPrivacy />} />
          <Route path='*' element={<Home />} />
        </Switch>
      )}
      <AlertModal
        isOpen={isAlertOpen}
        type={alertType}
        title={alertTitle}
        content={alertContent}
        yes={alertYes}
        no={alertNo}
        loading={alertLoading}
        btnText={alertText}
        onRequestClose={() => dispatch(closeAlert())}
      />
    </Router>
  );
};

export default Nav;
