import styles from './loader.module.css';
import colors from '../colorPalette';

const Loader = (props) => {
  const { size, color = colors.PRIMARY_COLOR, style } = props;

  const calculatedSize = typeof size === 'number' ? size : size === 'large' ? 100 : size === 'small' ? 20 : 50;

  return (
    <section className={styles.container} style={Object.assign({width: calculatedSize, height: calculatedSize}, style)}>
      <svg width={calculatedSize} height={calculatedSize} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17 3.5C9.54416 3.5 3.5 9.54416 3.5 17C3.5 21.4157 5.61848 25.3363 8.90087 27.8017C9.56325 28.2993 9.69689 29.2396 9.19935 29.902C8.70182 30.5643 7.76152 30.698 7.09913 30.2004C3.09484 27.1927 0.5 22.3992 0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 22.3992 30.9052 27.1927 26.9009 30.2004C26.2385 30.698 25.2982 30.5643 24.8006 29.902C24.3031 29.2396 24.4368 28.2993 25.0991 27.8017C28.3815 25.3363 30.5 21.4157 30.5 17C30.5 9.54416 24.4558 3.5 17 3.5Z'
          fill={color}
          className={styles.loaderPath}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17 9.63636C12.9332 9.63636 9.63636 12.9332 9.63636 17C9.63636 19.4086 10.7919 21.547 12.5823 22.8919C12.9436 23.1632 13.0165 23.6761 12.7451 24.0374C12.4737 24.3987 11.9608 24.4716 11.5995 24.2002C9.41537 22.5597 8 19.945 8 17C8 12.0294 12.0294 8 17 8C21.9706 8 26 12.0294 26 17C26 19.945 24.5846 22.5597 22.4005 24.2002C22.0392 24.4716 21.5263 24.3987 21.2549 24.0374C20.9835 23.6761 21.0564 23.1632 21.4177 22.8919C23.2081 21.547 24.3636 19.4086 24.3636 17C24.3636 12.9332 21.0668 9.63636 17 9.63636Z'
          fill={color}
          className={styles.loaderPath}
        />
      </svg>
    </section>
  );
};

export default Loader;
